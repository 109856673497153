.rrn-number{
    display:flex;
  align-items: center;
  margin-left: 30px;
  margin-top: 20px;
  gap: 40px;
}
 
.input-group input::placeholder{
    color:#458AD7;
    font-size: 17px;
}

 